// Generated by Framer (b9ee990)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xwfAvuU0I"];

const variantClassNames = {xwfAvuU0I: "framer-v-6naf7i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string}; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "xwfAvuU0I", image: Dj4xV90Xj, tap: OIuzBzjQa, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xwfAvuU0I", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapmwwyzc = activeVariantCallback(async (...args) => {
if (OIuzBzjQa) {
const res = await OIuzBzjQa(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-eo3kE", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-6naf7i", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"xwfAvuU0I"} onTap={onTapmwwyzc} ref={ref} style={{...style}} transition={transition}><Image background={{alt: "", fit: "fill", sizes: "min(552px, 100vw)", ...toResponsiveImage(Dj4xV90Xj)}} className={"framer-i0ydpi"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"DZ5n9nq62"} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-eo3kE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eo3kE .framer-bq45qp { display: block; }", ".framer-eo3kE .framer-6naf7i { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 665px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 552px; }", ".framer-eo3kE .framer-i0ydpi { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); overflow: hidden; position: absolute; top: calc(50.07518796992484% - 100% / 2); width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eo3kE .framer-6naf7i { gap: 0px; } .framer-eo3kE .framer-6naf7i > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-eo3kE .framer-6naf7i > :first-child { margin-left: 0px; } .framer-eo3kE .framer-6naf7i > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 665
 * @framerIntrinsicWidth 552
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Dj4xV90Xj":"image","OIuzBzjQa":"tap"}
 */
const FramerJx0il4JdT: React.ComponentType<Props> = withCSS(Component, css, "framer-eo3kE") as typeof Component;
export default FramerJx0il4JdT;

FramerJx0il4JdT.displayName = "Elemental/ Image Component";

FramerJx0il4JdT.defaultProps = {height: 665, width: 552};

addPropertyControls(FramerJx0il4JdT, {Dj4xV90Xj: {title: "Image", type: ControlType.ResponsiveImage}, OIuzBzjQa: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerJx0il4JdT, [])